import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import SectionDark from "./sections/section-dark"
import SectionLight from "./sections/section-light"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export default function About() {
  return (
    <>
      {/* about */}
      <span id="about" />
      <SectionLight>
        <h2 className="uk-heading-divider">About</h2>
        <h3>Overview</h3>
        <div className="uk-grid" uk-grid="true">
          <div className="uk-width-1-2@m uk-width-1-1@s">
            <p>
              The Standardized Centralized Alzheimer’s & Related Dementias
              Neuroimaging (SCAN) initiative is a multi-institutional project
              that was funded as a U24 grant (AG067418) by the National
              Institute on Aging (NIA) in May 2020 with the goal of
              standardizing the acquisition, curation, and analysis of PET and
              MR images acquired through the NIA Alzheimer’s Disease Research
              Center (ADRC) Program. The National Alzheimer’s Coordinating
              Center (NACC) Data Platform enriches the SCAN dataset by linking
              with the longitudinal Uniform Data Set and other data modalities
              at NACC.
            </p>
            <p>
              ADRCs upload SCAN-compliant images to a portal hosted by the
              Laboratory of Neuro Imaging (LONI) at the University of Southern
              California where they are de-identified and{" "}
              <a href="https://newsnetwork.mayoclinic.org/discussion/mayo-clinic-studies-patient-privacy-in-mri-research/#:~:text=Software%20programs%20to%20remove%20or,Schwarz.">
                defaced
              </a>{" "}
              by the Aging and Dementia Imaging Research (ADIR) laboratory at
              Mayo Clinic. The PET and MRI laboratories at the University of
              Michigan and the Aging and Dementia Research (ADIR) Laboratory at
              Mayo Clinic then process for quality assurance and harmonization.
              Following this, the PET laboratory at UC Berkeley and the MRI
              laboratories at Mayo Clinic and UC Davis analyze the images to
              produce MRI volumes and PET Standardized Uptake Value Ratio (SUVR)
              data.
            </p>
            <p>
              Recently, SCAN methods have been applied to the ADRC Consortium
              for Clarity in ADRD Research Through Imaging (CLARiTI).
            </p>
          </div>
          <div className="uk-width-1-2@m uk-width-1-1@s">
            <div className="uk-text-left@m uk-text-center@s ">
              <StaticImage
                src={"../assets/images/scan-schematic.png"}
                alt="A schematic of data flow. The caption describes this schematic."
                className="uk-border-rounded"
                quality={100}
              />
            </div>
          </div>
        </div>

        <div className="uk-padding-large">
          <SectionDark>
            <div className="uk-padding uk-border-rounded">
              <h2>Why is SCAN needed?</h2>
              <p className="text-color-light">
                For many years, researchers at the NIA-funded ADRCs have been
                collecting different types of PET and MR images. This “mixed
                protocol” or “legacy” data was collected using a variety of
                different acquisition methods such as T1-weighted imaging,
                diffusion imaging, or resting state functional MRI. Because of
                this, image data could not be easily combined across ADRCs,
                resulting in lost opportunities for scientific collaboration.
                The goal of SCAN is to standardize PET and MRI data collection
                from across the ADRC Program so that it can be combined and
                shared with researchers around the world via the NACC Data
                Platform.
              </p>
            </div>
          </SectionDark>
        </div>

        <div>
          <h3>Data Sharing</h3>
          <p>
            The NACC Data Platform makes harmonized images and numerical
            summaries (e.g., Standardized Uptake Value Ratio ‘SUVRs’ and
            volumes) available to ADRCs and approved researchers around the
            world through the following mechanisms:
          </p>
          <h4>
            ADRC Portals: ADRCs can access and download SCAN data for their
            participants
          </h4>
          <ul className="uk-list">
            <li>
              <p>
                <AnchorLink
                  to="/#faq"
                  className="uk-h5 uk-text-primary uk-button-text"
                >
                  SCAN QC Dashboards:
                </AnchorLink>{" "}
                ADRCs can track and audit their image submission’s quality
                control (QC) status and other summary information in real-time.
              </p>
            </li>
            <li>
              <p>
                <span className="uk-h5">All SCAN Variables:</span> Numerical
                values (SUVRs and volumes) will be accessible to ADRCs via the
                ADRC Portals for all their participants.
              </p>
            </li>
          </ul>
          <h4>
            NACC Data Front Door: Researchers anywhere can access SCAN data for
            free
          </h4>
          <div className="uk-grid" uk-grid="true">
            <ul className="uk-child-width-1-3@s uk-grid" data-uk-grid="true">
              <li>
                <p>
                  <AnchorLink
                    to="/#dashboard"
                    className="uk-h5 uk-text-primary uk-button-text"
                  >
                    SCAN Public Dashboard:
                  </AnchorLink>{" "}
                  The SCAN Public Dashboard provides researchers with up-to-date
                  information on the number of participants with SCAN-compliant
                  PET and MR images and the total number of images submitted by
                  each ADRC to SCAN.
                </p>
              </li>
              <li>
                <p>
                  <a
                    href="https://naccdata.org/requesting-data/submit-data-request"
                    className="uk-button-text uk-h5 uk-text-primary"
                  >
                    Quick Access File Data Request System:
                  </a>{" "}
                  Researchers can request SCAN data collected from all
                  participating ADRCs, including summary, QC, and analysis
                  (volumes and SUVRs) data. As of August 2024, Researchers can
                  also access defaced SCAN images via this data request system.
                </p>
              </li>
              <li>
                <p>
                  <a
                    href="https://naccdata.org/adrc-resources/nacc-data-platform#data%20front%20door"
                    className="uk-h5 uk-text-primary uk-button-text"
                  >
                    Cohort Selection Tool:
                  </a>{" "}
                  Coming in 2025! A new self-service tool coming to NACC
                  allowing researchers to perform cohort selection, data
                  visualization, and provide access to raw data at NACC
                  directly.
                </p>
              </li>
            </ul>
            <div className="uk-width-3-4@l uk-margin-auto">
              <StaticImage
                src={"../assets/images/dfd.png"}
                alt="Data Front Door overview"
                className="uk-border-rounded"
                quality={100}
                objectFit="contain"
              />
            </div>
          </div>
          <p className="">
            Raw images are stored at the Laboratory of Neuro Imaging (LONI).
            ADRCs and researchers interested in accessing the raw images will be
            able request them via LONI in the near future. NACC will provide
            updates on the availability of raw images as progress is made by
            SCAN, LONI, and NACC to ensure raw image data is de-identified
            before it is made available to the public.
          </p>

          <h4>SCAN Protocols</h4>
          <p>
            Prospective image files that meet SCAN acquisition protocols,
            regardless of the funding source, are submitted to LONI as part of
            this project. Existing images, and prospective images that don’t
            meet SCAN protocol, are instead submitted directly to NACC. <br />{" "}
            SCAN has a set of required acquisition schemes for PET and MR data.
            This includes required PET protocols for acquiring amyloid, tau, and
            fludeoxyglucose-18 (FDG) PET scans, and required MRI sequences for
            structural T1 and Fluid Attenuated Inversion Recovery (FLAIR) scans.
            Additional MRI sequences are optional. Analyzed data available to
            investigators will include:
          </p>
          <ul className="uk-list uk-list-collapse uk-margin-small-top uk-list-bullet">
            <li>
              <p>Regional measures of amyloid, tau, and FDG tracer uptake</p>
            </li>
            <li>
              <p>Brain volumes</p>
            </li>
            <li>
              <p>White matter hyperintensities</p>
            </li>
            <li>
              <p>Cerebral infarction</p>
            </li>
            <li>
              <p>
                Fractional anisotropy, cerebral blood flow, and functional
                connectivity (depending on the availability of advanced MRI
                sequences)
              </p>
            </li>
          </ul>
          <p>
            Current SCAN protocols are focused on widely accepted and applied
            biomarkers generally, but not exclusively, related to characterizing
            research participants in the{" "}
            <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4970664/">
              A/T/N framework
            </a>
            . To continue to support collaborative research, SCAN will base
            future directions on the needs and goals of the ADRC program. These
            may include other MRI techniques or PET tracers as best practices
            for their acquisition and analysis become available.
          </p>
        </div>
      </SectionLight>
      {/* mission */}
      {/* <SectionDark>
        <span id="mission" />
        <h2 className="uk-heading-divider">Mission</h2>
        <div className="uk-column-1-2@m uk-column-1-1@s">
          <p>
            For many years, researchers at the NIA-funded Alzheimer’s Disease
            Research Centers have been collecting different types of PET and MRI
            images on their research participants. These data, which we call
            “legacy data” were collected using different acquisition methods.
            Because of this, image data could not be simply combined across
            Centers, resulting in lost opportunities for scientific
            collaboration. Some legacy data is currently stored on the National
            Alzheimer’s Coordinating Center (NACC) website. However, for the
            immediate future, SCAN will not focus on this legacy data, but on
            prospectively acquired data.
          </p>
          <p>
            The goal of SCAN will be to promote standardization of PET and MR
            image acquisition so that images can be combined across multiple
            centers. These prospectively acquired images will be subject to
            standardized quality control procedures, and then uploaded to NACC
            where they will be appropriately archived, labeled, and made
            available to qualified investigators. In addition, the SCAN team
            will develop numerical summaries of the PET and MR data that will be
            available on the NACC website and linked to the ADRC participants
            through their ID.{" "}
            <strong>
              Appropriate images for inclusion in the SCAN workflow are images
              acquired prospectively on NACC participants, following SCAN
              protocols regardless of the funding source.
            </strong>
          </p>
          <p>
            SCAN has a set of required acquisition schemes for PET and MR data.
            This includes required PET protocols for acquiring amyloid, tau, and
            FDG PET scans, and required MRI sequences for structural T1 and
            FLAIR scans. Additional MR sequences are optional. Analyzed data
            available to investigators will include regional measures of
            amyloid, tau, and FDG tracer uptake, brain volumes, white matter
            hyperintensities, cerebral infarction, and, depending on the
            availability of advanced MR sequences, fractional anisotropy,
            cerebral blood flow, and functional connectivity.
          </p>
        </div>
      </SectionDark> */}
    </>
  )
}
