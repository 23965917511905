import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import SectionLight from "./sections/section-light"
import SectionDefault from "./sections/section-default"
import SectionDark from "./sections/section-dark"
import Submit from "./submit"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"

export default function Resources() {
  return (
    <>
      <span id="resources" />
      <SectionLight>
        <h2 className="uk-heading-divider">Resources for ADRCs</h2>
        <div>
          <h3>How to Participate</h3>
          <ul uk-accordion="true">
            <li>
              <AnchorLink className="uk-accordion-title" to="">
                Acquire Images
              </AnchorLink>
              <div className="uk-accordion-content uk-padding uk-border-rounded uk-text">
                <p>
                  Alzheimer’s Disease Research Centers participating in SCAN
                  should refer to the MRI and PET checklist and manuals linked
                  below for detailed SCAN image acquisition protocols.
                </p>
                <p>
                  SCAN will troubleshoot prospectively with ADRCs to advise on
                  making data SCAN compliant.{" "}
                  <AnchorLink to="/#contact">Contact SCAN</AnchorLink>.
                </p>
                <h4>Current SCAN Protocols</h4>
                <div className="uk-grid-divider" uk-grid="true">
                  <div className="uk-width-1-2@m uk-width-1-1@s">
                    <h3>MRI</h3>
                    <a
                      href="https://files.alz.washington.edu/scan/SCAN_MRI_Manual.pdf"
                      title="MRI technical procedures manual PDF"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MRI Checklist and Manual
                    </a>
                    <p>
                      All longitudinal scans must be performed on the same MRI
                      scanner.
                    </p>
                    <p>
                      Each MRI scanner must be certified and approved by SCAN.
                      MR scanners that are now certified for ADNI 3 can be
                      certified for SCAN using a phantom scan. MR scanners that
                      have not been certified for ADNI 3 (or SCAN) must be
                      certified using a human volunteer scan. This must be
                      permissible using a local IRB &ndash; i.e. SCAN does not
                      plan to create a central IRB nor will SCAN manage local
                      IRB administrative proceedures.
                    </p>
                    <p>
                      The Standard MRI protocol (using SCAN-specific parameters)
                      is required. Your remaining scan time can be used for your
                      site specific sequences.
                    </p>
                    <p>
                      There are 2 options for SCAN MR – each site can choose
                      which they prefer.
                    </p>
                    <span className="uk-text-uppercase uk-text-bold">
                      Option 1:
                    </span>{" "}
                    Site only required to do 2 primary SCAN sequences: T1 &amp;
                    FLAIR
                    <ul className="uk-list uk-list-bullet">
                      <li>
                        <p>
                          The <em>first 10 minutes</em> of exam belongs to
                          NIA/SCAN &mdash; this is when the SCAN T1 &amp; FLAIR
                          would be done
                        </p>
                      </li>
                      <li>
                        <p>Remainder of exam is unique to site</p>
                      </li>
                      <li>
                        <p>
                          SCAN would <em>only</em> collect and analyze T1 and
                          FLAIR
                        </p>
                      </li>
                    </ul>
                    <span className="uk-text-uppercase uk-text-bold">
                      Option 2:
                    </span>{" "}
                    Site may do full SCAN protocol (which mirrors ADNI)
                  </div>
                  <div className="uk-width-1-2@m uk-width-1-1@s">
                    <h3>PET</h3>
                    <a
                      href="https://files.alz.washington.edu/scan/SCAN_PET_Manual.pdf"
                      title="PET technical procedures manual PDF"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PET Checklist and Manual
                    </a>
                    <p>
                      All longitudinal scans must be performed on the same PET
                      scanner.
                    </p>
                    <p>
                      Each PET scanner must be certified and approved by SCAN.
                      ADNI approved PET scanners will not need to be
                      re-certified.
                    </p>
                    <p>
                      The following tracers and imaging protocols are all
                      compatible with SCAN:
                    </p>

                    <table className="uk-table uk-table-small uk-table-divider uk-table-middle uk-text-center">
                      <thead>
                        <tr>
                          <th aria-label="spacer" className="uk-width-1-4">
                            &nbsp;
                          </th>
                          <th className="uk-width-1-4 color-gray" colSpan={2}>
                            Suggested Target Dose &#177; 10%
                          </th>
                          <th className="uk-width-1-4 color-gray" colSpan={2}>
                            Minimum injectable dose
                          </th>
                          <th
                            className="uk-width-1-4 color-gray"
                            colSpan={2}
                            rowSpan={2}
                          >
                            Acquisition start-stop time post-injection (min)
                          </th>
                        </tr>
                        <tr style={{ borderTopWidth: "3px" }}>
                          <th className="uk-text-left color-gray">Tracer</th>
                          <th className="color-gray">mCi</th>
                          <th className="color-gray">MBq</th>
                          <th className="color-gray">mCi</th>
                          <th className="color-gray">MBq</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ borderTopWidth: "3px" }}>
                          <th colSpan={6} className="uk-text-center color-red">
                            <strong>Amyloid Tracers</strong>
                          </th>
                        </tr>
                        <tr>
                          <th className="color-red">PIB</th>
                          <td className="color-red">15</td>
                          <td className="color-red">555</td>
                          <td className="color-red">8</td>
                          <td className="color-red">300</td>
                          <td className="color-red">
                            40&ndash;60 or 50&ndash;70, 40&ndash;70
                          </td>
                        </tr>
                        <tr>
                          <th className="color-red">Florbetapir</th>
                          <td className="color-red">10</td>
                          <td className="color-red">370</td>
                          <td className="color-red">6</td>
                          <td className="color-red">225</td>
                          <td className="color-red">50&ndash;70</td>
                        </tr>
                        <tr>
                          <th className="color-red">Florbetaben</th>
                          <td className="color-red">8</td>
                          <td className="color-red">295</td>
                          <td className="color-red">5</td>
                          <td className="color-red">185</td>
                          <td className="color-red">90&ndash;110</td>
                        </tr>
                        <tr>
                          <th className="color-red">NAV4694</th>
                          <td className="color-red">8.1</td>
                          <td className="color-red">300</td>
                          <td className="color-red">6.5</td>
                          <td className="color-red">240</td>
                          <td className="color-red">
                            40&ndash;70, 50&ndash;70
                          </td>
                        </tr>
                        <tr>
                          <th className="color-red">Flutemetamol</th>
                          <td className="color-red">5</td>
                          <td className="color-red">185</td>
                          <td className="color-red">4</td>
                          <td className="color-red">150</td>
                          <td className="color-red">90&ndash;110</td>
                        </tr>
                        <tr style={{ borderTopWidth: "3px" }}>
                          <th colSpan={6} className="uk-text-center color-blue">
                            <strong>TAU Tracers</strong>
                          </th>
                        </tr>
                        <tr>
                          <th className="color-blue">Flortaucipir</th>
                          <td className="color-blue">10</td>
                          <td className="color-blue">370</td>
                          <td className="color-blue">6</td>
                          <td className="color-blue">225</td>
                          <td className="color-blue">80&ndash;100</td>
                        </tr>
                        <tr>
                          <th className="color-blue">MK6240</th>
                          <td className="color-blue">5</td>
                          <td className="color-blue">185</td>
                          <td className="color-blue">4</td>
                          <td className="color-blue">150</td>
                          <td className="color-blue">
                            70&ndash;90 or 90&ndash;110, 70&ndash;110
                          </td>
                        </tr>
                        <tr>
                          <th className="color-blue">PI2620</th>
                          <td className="color-blue">5</td>
                          <td className="color-blue">185</td>
                          <td className="color-blue">4</td>
                          <td className="color-blue">150</td>
                          <td className="color-blue">
                            30&ndash;60, 45&ndash;75, 60&ndash;90, 45&ndash;90
                          </td>
                        </tr>
                        <tr>
                          <th className="color-blue">GTP1</th>
                          <td className="color-blue">7</td>
                          <td className="color-blue">260</td>
                          <td className="color-blue">5</td>
                          <td className="color-blue">185</td>
                          <td className="color-blue">60&ndash;90</td>
                        </tr>
                        <tr style={{ borderTopWidth: "3px" }}>
                          <th
                            colSpan={6}
                            className="uk-text-center color-green"
                          >
                            <strong>Glucose metabolism tracer</strong>
                          </th>
                        </tr>
                        <tr>
                          <th className="color-green">FDG</th>
                          <td className="color-green">5</td>
                          <td className="color-green">185</td>
                          <td className="color-green">4</td>
                          <td className="color-green">150</td>
                          <td className="color-green">
                            30&ndash;45, 30&ndash;60
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="uk-width-1-1">
                    <p>
                      <b>NOTE: </b>
                      The SCAN MR protocol is the same as the Alzheimer’s
                      Disease Neuroimaging Initiative (ADNI) protocol. The
                      current protocol is ADNI 4. See this set of{" "}
                      <a
                        href="https://adni.loni.usc.edu/methods/documents/"
                        title="ADNI MR Protocols"
                      >
                        ADNI MR protocols
                      </a>{" "}
                      created for various vendor/model/operating system options.
                    </p>
                  </div>
                </div>
                {/* <div className="uk-margin-large-top">
                  <StaticImage
                    src={"../assets/images/process-detail.png"}
                    alt="A schematic of data flow. The caption describes this schematic."
                    quality={90}
                  />
                </div> */}
              </div>
            </li>

            <li>
              <AnchorLink className="uk-accordion-title" to="">
                Upload Images
              </AnchorLink>
              <div className="uk-accordion-content uk-padding uk-border-rounded uk-text">
                <Submit />
              </div>
            </li>

            <li>
              <AnchorLink className="uk-accordion-title" to="">
                Track and audit image submissions
              </AnchorLink>
              <div className="uk-accordion-content uk-background-muted uk-padding uk-border-rounded uk-text">
                <p>
                  Sites are responsible for auditing their submissions and
                  making sure that the information is correct using the SCAN QC
                  Dashboards.
                </p>
                <div>
                  <AnchorLink
                    to="/#dashboard"
                    title="SCAN Dashboard"
                    className="uk-button uk-button-primary"
                  >
                    View the SCAN Dashboards
                  </AnchorLink>
                </div>
                <p>
                  Each ADRC can access their ADRC-specific SCAN QC Dashboards
                  via the SCAN Public Dashboard.
                </p>
                <ul className="uk-list uk-list-bullet">
                  <li>
                    <p>
                      These enable sites to track the QC status of their MRI and
                      PET submissions, allowing them to rapidly address errors.
                      They also serve as an auditable record of all SCAN MRI
                      data submitted to SCAN by a given ADRC and are
                      downloadable as a csv file.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>NOTE</b>: The ADRC-specific SCAN QC Dashboards are only
                      available to ADRC staff with data access privileges.
                      Please refer to the{" "}
                      <a
                        href="https://files.alz.washington.edu/scan/scan_dashboard_quick_guide.pdf"
                        title="SCAN Dashboard Quick Access Guide"
                      >
                        SCAN Dashboard Quick Access Guide
                      </a>{" "}
                      for details.
                    </p>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <AnchorLink className="uk-accordion-title" to="">
                Access your SCAN data and analysis results
              </AnchorLink>
              <div className="uk-accordion-content uk-padding uk-border-rounded uk-text">
                <p>
                  All SCAN variables will soon be available via ADRC Portals. We
                  anticipate this data being available by Fall 2024.
                </p>
                <p>
                  In the meantime, ADRCs may access analysis results by
                  submitting a data request via NACC’s Quick Access File Data
                  Request System.
                </p>
                <div>
                  <a
                    href="https://naccdata.org/requesting-data/submit-data-request"
                    title="Request SCAN data at NACC"
                    className="uk-button uk-button-primary"
                  >
                    Request SCAN data at NACC
                  </a>
                </div>
                <p>
                  <b>NOTE</b>: Analysis results are calculated after defacing
                  and QC has been performed on MR and PET images. Sites should
                  expect a delay from when images are submitted to when analysis
                  results are available.
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className="uk-margin-bottom">
          <h3>Data Resources</h3>
          <AnchorLink to="#data-resources" title="Data Resources" className="">
            See Below{" "}
            <FontAwesomeIcon icon={faArrowDown} size="1x"></FontAwesomeIcon>
          </AnchorLink>
        </div>
        <div>
          <h3>SCAN team Tips for successful uploads</h3>
          <ol className="uk-list uk-list-decimal">
            <li>
              <p className="uk-text-bold">
                Qualify all scanner(s) to the SCAN protocol for MRI and PET as
                per the{" "}
                <a
                  href="https://files.alz.washington.edu/scan/SCAN_MRI_Manual.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  SCAN MRI manual
                </a>{" "}
                and the{" "}
                <a
                  href="https://files.alz.washington.edu/scan/SCAN_PET_Manual.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  SCAN PET manual
                </a>
                .
              </p>
              <ul className="uk-list uk-list-circle">
                <li>
                  For MRI: Contact the Mayo ADIR Lab at{" "}
                  <a href="mailto:scanmri@mayo.edu">scanmri@mayo.edu</a>
                </li>
                <li>
                  For PET: Contact Suzanne Baker at{" "}
                  <a href="mailto:slbaker@lbl.gov">slbaker@lbl.gov</a>
                </li>
              </ul>
            </li>
            <li>
              <p className="uk-text-bold">
                Do not self-deface series or scans.
              </p>
              <ul className="uk-list uk-list-circle">
                <li>
                  This is done centrally by SCAN for all relevant series prior
                  to image release.
                </li>
                <li>
                  Defacing multiple times introduces undesirable data
                  heterogeneity.
                </li>
              </ul>
            </li>
            <li>
              <p className="uk-text-bold">Do not self-anonymize DICOMs.</p>
              <ul className="uk-list uk-list-circle">
                <li>This is done by LONI upon image upload.</li>
                <li>
                  May be impossible to protocol check or even display an image.
                </li>
              </ul>
            </li>
            <li>
              <p className="uk-text-bold">
                Do not upload the same exam(s) or scan(s) multiple times.
              </p>
              <ul className="uk-list uk-list-circle">
                <li>
                  Uploading exams multiple times results in unnecessary
                  investigation and clean up time/effort which diminishes
                  throughput.
                </li>
              </ul>
            </li>
            <li>
              <p className="uk-text-bold">
                Ensure correct participant ID is assigned to the upload exam.
              </p>
              <ul className="uk-list uk-list-circle">
                <li>
                  Failure to do so results in unnecessary investigation and
                  clean up time/effort which diminishes throughput.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            For PET, do not upload non-attenuation corrected data (NAC), also do
            not upload summed frames (anything longer than 5 min per frame).
          </p>
        </div>
        {/* <p className="uk-text-lead uk-margin-large-bottom">
          The SCAN MR protocol is the same as ADNI. The current protocol is ADNI
          3 and this is what will be used initially in SCAN, but ADNI 4 MR
          protocol will be preferred when it is finalized. See this{" "}
          <a
            href="http://adni.loni.usc.edu/methods/documents"
            title="Link to set of ADNI MRI protocol"
            target="_blank"
            rel="noopener noreferrer"
          >
            set of ADNI MR protocols
          </a>{" "}
          which have been created for a variety of vendor/model/operating system
          options.
        </p> */}
        {/* <h3 className="uk-h5 uk-heading-divider">Current protocols</h3> */}
        {/* <div
          className="uk-padding uk-margin-large-top"
          style={{ backgroundColor: "rgba(0,0,0,.05)" }}
        >
          <p className="uk-text-bold uk-margin-remove-bottom">Notes</p>
          <div className="uk-child-width-1-2@m" uk-grid="true">
            <div>
              <ol className="uk-list uk-list-collapse uk-list-decimal uk-margin-remove-top">
                <li>
                  SCAN will troubleshoot prospectively with ADRCs to advise on
                  making data SCAN compliant.
                </li>
                <li>De-face decision pending.</li>
                <li>
                  Only SCAN approved data should be uploaded to LONI, however if
                  non-approved data are uploaded (with or without approved data)
                  it will be quarantined.
                </li>
              </ol>
            </div>
            <div>
              <ol
                className="uk-list uk-list-collapse uk-list-decimal uk-margin-remove-top"
                start={4}
              >
                <li>
                  Fate of non-SCAN compliant data uploaded to LONI is currently
                  uncertain — for future decision.
                </li>
                <li>
                  “Legacy data” already at NACC: decision pending for
                  management/curation/analysis.
                </li>
              </ol>
            </div>
          </div>
        </div> */}
      </SectionLight>

      {/* Resources for Researchers */}
      <SectionDefault>
        <span id="researchers" />
        <h2 className="uk-heading-divider">Resources for Researchers</h2>
        <p>
          Researchers around the world can access Standardized Centralized
          Alzheimer’s Disease and Related Dementias Neuroimaging (SCAN)
          initiative harmonized images and numerical summaries (e.g.
          Standardized uptake value ratio ‘SUVRs’ and volumes) via the National
          Alzheimer’s Coordinating Center (NACC) Data Platform through NACC’s
          Data Front Door.
        </p>
        <h3>NACC's Data Front Door</h3>
        <p>Free access is available via the following mechanisms:</p>
        <div className="uk-grid-divider uk-child-width-1-2@m" uk-grid="true">
          <div>
            <h4>SCAN Public Dashboard</h4>
            <p className="uk-margin-remove">
              Up-to-date information about the number of participants with
              SCAN-compliant MR/PET images and the total number of images
              submitted by each Alzheimer’s Disease Research Center (ADRC) to
              SCAN.
            </p>
            <p>
              <AnchorLink
                to="#dashboard"
                title="SCAN Public Dashboard"
                target="_blank"
                rel="noopener noreferrer"
                className="uk-button uk-button-primary"
              >
                View the SCAN Dashboard
              </AnchorLink>
            </p>
          </div>

          <div>
            <h4>Quick Access File Data Request System</h4>
            <p className="uk-margin-remove">
              Researchers can request SCAN numerical data collected from all
              participating ADRCs, including summary, QC, and analysis (volumes,
              cortical thickness, surface area, and SUVRs) data. As of August
              2024, Researchers can also access defaced SCAN images via this
              data request system.
            </p>
            <p>
              <a
                href="https://naccdata.org/requesting-data/submit-data-request"
                title="Request SCAN data at NACC"
                target="_blank"
                rel="noopener noreferrer"
                className="uk-button uk-button-primary"
              >
                Request SCAN data at NACC
              </a>
            </p>
          </div>

          <div>
            <h4>Raw Image Availability</h4>
            <p className="uk-margin-remove">
              Images are currently stored at the Laboratory of Neuro Imaging
              (LONI). Researchers can access these images via NACC’s Quick
              Access File Data Request System. NOTE: Due to the processing time
              needed to perform defacing, QC, and analysis on submitted data,
              not all image data that has been submitted and/or passed through
              QC is available to researchers at this time.
            </p>
          </div>

          <div>
            <h4>Cohort Selection Tool:</h4>
            <p className="uk-margin-remove">
              Coming in 2025! A new self-service tool coming to NACC allowing
              researchers to perform cohort selection, data visualization, and
              provide access to raw data at NACC directly.
            </p>
          </div>
        </div>

        <h3>Data Acknowledgements</h3>
        <p>
          IF USING MRI AND/OR PET DATA FROM THE STANDARDIZED CENTRALIZED
          ALZHEIMER’S DISEASE NEUROIMAGING (SCAN) INITIATIVE, A STATEMENT
          ACKNOWLEDGING THE SCAN GRANT AND CENTERS IS REQUIRED IN PRESENTATIONS
          AND THE ACKNOWLEDGMENTS SECTION OF MANUSCRIPTS AS DETAILED{" "}
          <a href="https://naccdata.org/publish-project/authors-checklist#citing%20the%20grant">
            HERE
          </a>
          .
        </p>
      </SectionDefault>

      {/* DATA RESOURCES */}
      <SectionDark>
        <span id="data-resources" />
        <h2 className="uk-heading-divider">Data Resources</h2>
        <ul className="uk-list uk-list-bullet">
          <li>
            <a
              href="https://files.alz.washington.edu/documentation/SCAN-MRI-Imaging-RDD.pdf"
              title="SCAN MRI Researcher’s Data Dictionary"
              target="_blank"
              rel="noopener noreferrer"
              className="uk-button uk-button-text"
            >
              SCAN MRI Researcher’s Data Dictionary
            </a>
          </li>
          <li>
            <a
              href="https://files.alz.washington.edu/documentation/SCAN-PET-Imaging-RDD.pdf"
              title="SCAN PET Researcher’s Data Dictionary"
              target="_blank"
              rel="noopener noreferrer"
              className="uk-button uk-button-text"
            >
              SCAN PET Researcher’s Data Dictionary
            </a>
          </li>
          <li>
            <a
              href="https://files.alz.washington.edu/scan/SCAN_MRI_Analysis_Methods.pdf"
              title="MRI Methods Documentation"
              target="_blank"
              rel="noopener noreferrer"
              className="uk-button uk-button-text"
            >
              MRI Methods Documentation
            </a>
          </li>
          <li>
            <a
              href="https://files.alz.washington.edu/scan/UCBerkeley_SCAN_Tau_MRIfree_Methods.pdf"
              title="Tau PET (MRI-free) Methods Documentation"
              target="_blank"
              rel="noopener noreferrer"
              className="uk-button uk-button-text"
            >
              Tau PET (MRI-free) Methods Documentation
            </a>
          </li>
          <li>
            <a
              href="https://files.alz.washington.edu/scan/UCBerkeley_SCAN_Amyloid_MRIfree_Methods.pdf"
              title="Amyloid PET (MRI-free) Methods Documentation"
              target="_blank"
              rel="noopener noreferrer"
              className="uk-button uk-button-text"
            >
              Amyloid PET (MRI-free) Methods Documentation
            </a>
          </li>
          <li>
            <a
              href="https://files.alz.washington.edu/scan/UCBerkeley_SCAN_FDG_Methods_20240529.pdf"
              title="FDG PET (MRI-free) Methods Documentation"
              target="_blank"
              rel="noopener noreferrer"
              className="uk-button uk-button-text"
            >
              FDG PET (MRI-free) Methods Documentation
            </a>
          </li>
        </ul>
        <h3>Data Acknowledgements</h3>
        <p className="bolder">
          IF USING MRI AND/OR PET DATA FROM THE STANDARDIZED CENTRALIZED
          ALZHEIMER’S DISEASE NEUROIMAGING (SCAN) INITIATIVE, A STATEMENT
          ACKNOWLEDGING THE SCAN GRANT AND CENTERS IS REQUIRED IN PRESENTATIONS
          AND THE ACKNOWLEDGMENTS SECTION OF MANUSCRIPTS AS DETAILED{" "}
          <a href="https://naccdata.org/publish-project/authors-checklist#citing%20the%20grant">
            HERE
          </a>
          .
        </p>
      </SectionDark>
    </>
  )
}
