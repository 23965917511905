import React from "react"
import Layout from "../components/layout"

import Seo from "../components/seo"
import SectionHome from "../components/section-home"
import About from "../components/about"
import Resources from "../components/resources"
import SupportFaq from "../components/support-faq"
import Team from "../components/team"

import FaqData from "../content/faqs.json"
import Footer from "../components/footer"
import NewUpdates from "../components/new-updates"
import DashboardSection from "../components/dashboard-section"
import Contact from "../components/contact"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight"

// const hasUpdates = updates && updates.data.length > 0
const hasUpdates = false // For now, silence updates

export default function IndexPage() {
  return (
    <Layout hasUpdates={hasUpdates}>
      <Seo title="Home" />

      {/* Data Availability Announcement */}
      <div className="uk-label-success uk-flex uk-flex-center uk-width-1-1 uk-padding ">
        <div className="uk-width-2xlarge">
          <h2 style={{ color: "white" }}>NACC Data Release Spotlight!</h2>
          <p
            className="uk-text-break uk-width-1-1"
            style={{ textWrap: "initial" }}
          >
            <span className="">
              SCAN PET and MRI data are{" "}
              <span className="uk-text-bold">now available</span> to the
              scientific community.
            </span>
            <br />
            <br />
            This unprecedented dataset contains summary and analysis variables
            extracted from standardized amyloid PET scans, tau PET scans, and
            MRI scans connected to comprehensive clinical and cognitive data
            from NACC’s Uniform Data Set (UDS) and other data modalities
            available at NACC.{" "}
          </p>
          <a
            href="https://nacc.redcap.rit.uw.edu/surveys/?s=KHNPKLJW8TKAD4DA"
            title="upload mixed protocol 'non-SCAN-compliant' data to NACC"
            className="uk-button uk-button-primary"
          >
            Request SCAN data at NACC
            <FontAwesomeIcon
              icon={faArrowRight}
              size="1x"
              className="uk-margin-small-left"
            />
          </a>
        </div>
      </div>

      {/* hero */}
      <SectionHome />

      {/* about */}
      <About />

      {/* dashboard */}
      <DashboardSection />

      {/* protocols */}
      <Resources />

      {/* team & contact & copyright */}
      <Team />

      {/* updates */}
      {/* <UpdateLister hasUpdates={hasUpdates} updates={updates} /> */}
      <NewUpdates />

      {/* support & FAQs */}
      <SupportFaq faqs={FaqData} />

      {/* contact */}
      <Contact />

      {/* footer */}
      <Footer />
    </Layout>
  )
}
