import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Collaborators from "./collaborators"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

export default function SectionHome() {
  return (
    <>
      <span id="hero" />
      <section className="uk-section bg-color-light-blue">
        <div className="uk-container">
          <div className="uk-grid uk-grid-small" uk-grid="true">
            <div className="uk-width-2-3@m">
              <StaticImage
                src={"../assets/images/mri-machine.png"}
                alt="MRI machine"
                style={{ marginTop: "-3em", marginLeft: "-9em" }}
                loading={"eager"}
                quality={100}
              />
            </div>
            <div className="uk-width-1-3@m uk-width-1-2 uk-position-relative">
              <div className="uk-position-center" style={{ marginTop: "-3em" }}>
                <StaticImage
                  src={"../assets/images/scan-logo-color.png"}
                  quality={100}
                  alt="SCAN"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section">
        <div className="uk-container">
          <h2>
            Standardized Centralized Alzheimer’s & Related Dementias
            Neuroimaging (SCAN)
          </h2>
          <p className="">
            The goal of SCAN is to enable standardized PET and MRI data
            collection from across the Alzheimer’s Disease Research Centers
            (ADRC) Program so that it can be combined and shared with
            researchers around the world via the NACC Data Platform. The
            National Institute on Aging (NIA) provides SCAN’s funding.
          </p>
          <h4>The SCAN initiative is a collaboration between:</h4>
          <div className="uk-width-2xlarge uk-margin-auto">
            <Collaborators />
          </div>
        </div>
      </section>

      <div class="uk-section uk-section-primary uk-preserve-color">
        <div class="uk-container">
          <div class="uk-panel uk-light uk-margin-medium">
            <h2>Submit SCAN Data</h2>
          </div>
          <div className="uk-label-warning uk-margin-large-top uk-margin-large-bottom uk-text-center uk-text-large uk-text-primary uk-padding-small">
            THE SCAN INITIATIVE ONLY ACCEPTS SCAN-COMPLIANT DATA <br /> Images
            that don't meet SCAN protocol are still accepted by NACC.{" "}
            <a
              className="uk-button-text uk-text-bold uk-text-primary "
              href="https://naccdata.org/data-collection/nonstandard-image-submission"
            >
              Learn more
              <FontAwesomeIcon
                icon={faArrowRight}
                size="1x"
                className="uk-margin-small-left"
              />
            </a>
          </div>

          <div class="uk-grid-match uk-child-width-expand@m" uk-grid>
            <div>
              <div class="uk-card uk-card-default uk-card-body">
                <div className="uk-grid-divider" uk-grid="true">
                  <div className="uk-width-1-2@m">
                    <h4>Submit SCAN-Compliant data to LONI</h4>
                    <p>
                      Please carefully review the{" "}
                      <a
                        href="https://files.alz.washington.edu/scan/SCAN_PET_Manual.pdf"
                        title="SCAN PET Manual"
                      >
                        SCAN PET manual
                      </a>{" "}
                      and the{" "}
                      <a
                        href="https://files.alz.washington.edu/scan/SCAN_MRI_Manual.pdf"
                        title="SCAN MRI Manual"
                      >
                        SCAN MRI manual
                      </a>{" "}
                      to ensure that your data is compliant with the SCAN
                      protocol and for uploading instructions.
                    </p>
                    <p>
                      Only SCAN-compliant data should be submitted to the
                      Laboratory of Neuro Imaging (LONI). Non-approved data
                      (with or without approved data) will be quarantined.
                      Images collected before 2021 do not meet SCAN-compliance
                      criteria.
                    </p>
                    <div>
                      <a
                        href="https://ida.loni.usc.edu/"
                        title="Upload SCAN-compliant data to LONI"
                        className="uk-button uk-button-primary"
                      >
                        Submit SCAN-compliant MRI/PET data
                      </a>
                    </div>
                  </div>
                  <div className="uk-width-1-2@m">
                    <h4>Submit non–SCAN-compliant data directly to NACC</h4>
                    <p>
                      SCAN does not accept data that is not prospectively
                      collected and SCAN-compliant.
                    </p>
                    <p>
                      Any data that is 'mixed protocol' or non-SCAN-compliant
                      should be submitted to NACC.
                    </p>
                    <div>
                      <a
                        href="https://naccdata.org/data-collection/nonstandard-image-submission"
                        title="upload mixed protocol 'non-SCAN-compliant' data to NACC"
                        className="uk-button uk-button-primary"
                      >
                        Submit Mixed Protocol MRI/PET data
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
