import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import React, { useEffect, useMemo, useState } from "react"
import useScanData from "../hooks/useScanData"
import { csvFormat } from "d3"
import { saveAs } from "file-saver"

export function InfoIcon({ info }) {
  return (
    <div className="relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="peer h-5 w-5"
        style={{ height: "1rem", width: "1rem" }}
        // onClick={() => alert(info)}
        data-uk-tooltip={info}
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
          clipRule="evenodd"
        />
      </svg>
      <div className="absolute bottom-6 hidden w-72 rounded bg-gray-900 bg-opacity-90 p-4 text-center text-white transition-all peer-hover:block">
        {/* {info} */}
      </div>
    </div>
  )
}

// A debounced input react component
export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input
      {...props}
      value={value}
      onSubmit={(e) => e.preventDefault()}
      onChange={(e) => setValue(e.target.value)}
    />
  )
}

function scanRowAccessor(row, label) {
  return row[label] != null ? String(row[label]) : "Error"
}

function HeaderCell(props) {
  return (
    <div>
      <span className="">
        {props.title}
        {props.info && <InfoIcon info={props.info} />}
      </span>
    </div>
  )
}

export function ScanDashLayout({ children }) {
  return (
    <div className="uk-section uk-section-default uk-border-rounded uk-padding">
      {children}
    </div>
  )
}

const apiColumns = [
  {
    id: 4377552064145284,
    version: 0,
    index: 0,
    title: "Institution (ADRC)",
    type: "TEXT_NUMBER",
    primary: true,
    validation: false,
    width: 262,
  },
  {
    id: 8881151691515780,
    version: 0,
    index: 1,
    title: "Update your SCAN readiness status",
    description:
      "Click the link below to update your ADRC's SCAN readiness status.",
    type: "TEXT_NUMBER",
    validation: false,
    width: 150,
  },
  {
    id: 3867129560754052,
    version: 0,
    index: 2,
    title: "Current SCAN readiness status",
    description:
      "An ADRC's SCAN readiness status refers to the MRI and/or PET checklist steps it has completed. (Ready to Submit Data = has completed the checklist and has submitted data to SCAN or is ready to submit data to SCAN) (Not submitting data = site has not completed the MRI and/or PET checklist and is not submitting data to SCAN)",
    type: "TEXT_NUMBER",
    validation: false,
    width: 150,
  },
  {
    id: 85058669307780,
    version: 0,
    index: 3,
    title: "Total # of Participants",
    description:
      "Total number of unique SCAN participants for whom a site has submitted MRI and/or PET images to SCAN",
    type: "TEXT_NUMBER",
    validation: false,
    width: 150,
  },
  {
    id: 4588658296678276,
    version: 0,
    index: 4,
    title: "# MRI participants",
    description:
      "Total number of unique participants for whom a site has submitted MRI images to SCAN",
    type: "TEXT_NUMBER",
    validation: false,
    width: 150,
  },
  {
    id: 2336858482993028,
    version: 0,
    index: 5,
    title: "# MRI exams",
    description:
      "Total number of unique participants for whom MRI exams have been submitted",
    type: "TEXT_NUMBER",
    validation: false,
    width: 150,
  },
  {
    id: 6840458110363524,
    version: 0,
    index: 6,
    title: "# MRI series",
    description: "Total number of MRI series that have been submitted to SCAN",
    type: "TEXT_NUMBER",
    validation: false,
    width: 150,
  },
  {
    id: 437836545582980,
    version: 0,
    index: 7,
    title: "# PET participants",
    description:
      "Total number of unique participants for whom a site has submitted PET images to SCAN",
    type: "TEXT_NUMBER",
    validation: false,
    width: 150,
  },
  {
    id: 4941436172953476,
    version: 0,
    index: 8,
    title: "# PET scans",
    description:
      "Total number of PET scans (amyloid or tau) that a site has submitted to SCAN",
    type: "TEXT_NUMBER",
    validation: false,
    width: 150,
  },
  {
    id: 2689636359268228,
    version: 0,
    index: 9,
    title: "Review MRI submissions to date & check QC status",
    type: "TEXT_NUMBER",
    validation: false,
    width: 150,
  },
  {
    id: 7538043242473348,
    version: 0,
    index: 10,
    title: "Review PET submissions to date & check QC status",
    type: "TEXT_NUMBER",
    validation: false,
    width: 150,
  },
]
const columnHelper = createColumnHelper()

export default function ScanDashboard() {
  const columns = [
    columnHelper.accessor((row) => scanRowAccessor(row, "display_name"), {
      id: "0",
      maxSize: 10,
      header: apiColumns[0].title.split(" ", 1),
      style: "color-blue",
      cell: (info) => <span className="">{info.getValue()}</span>,
      footer: () => <p className="uk-text-left ">Total</p>,
    }),
    columnHelper.group({
      header: "SCAN Participation",
      style: "color-gray",
      columns: [
        columnHelper.accessor(
          (row) => scanRowAccessor(row, "total_participants"),
          {
            id: "3",
            header: () => (
              <HeaderCell
                title={apiColumns[3].title}
                info={apiColumns[3].description}
              />
            ),
            style: "color-blue",
            cell: (info) => (
              <p className="uk-text-right ">
                {Number(info.getValue()).toLocaleString()}
              </p>
            ),
            sortDescFirst: true,
            aggregationFn: "sum",
            footer: () => grandTotals[3].toLocaleString(),
          }
        ),
        columnHelper.group({
          header: "MRI",
          style: "color-gray",
          columns: [
            columnHelper.accessor(
              (row) => scanRowAccessor(row, "mri_participants"),
              {
                id: "4",
                header: () => (
                  <HeaderCell
                    title={apiColumns[4].title}
                    info={apiColumns[4].description}
                  />
                ),
                style: "color-blue",
                sortDescFirst: true,
                footer: () => grandTotals[4].toLocaleString(),
                cell: (info) => (
                  <p className="uk-text-right">
                    {Number(info.getValue()).toLocaleString()}
                  </p>
                ),
              }
            ),
            columnHelper.accessor(
              (row) => scanRowAccessor(row, "mri_exams_submitted"),
              {
                id: "5",
                header: () => (
                  <HeaderCell
                    title={apiColumns[5].title}
                    info={apiColumns[5].description}
                  />
                ),
                style: "color-blue",
                sortDescFirst: true,
                footer: () => grandTotals[5].toLocaleString(),
                cell: (info) => (
                  <p className="uk-text-right">
                    {Number(info.getValue()).toLocaleString()}
                  </p>
                ),
              }
            ),
            columnHelper.accessor(
              (row) => scanRowAccessor(row, "mri_series_submitted"),
              {
                id: "6",
                header: () => (
                  <HeaderCell
                    title={apiColumns[6].title}
                    info={apiColumns[6].description}
                  />
                ),
                style: "color-blue",
                sortDescFirst: true,
                footer: () => grandTotals[6].toLocaleString(),
                cell: (info) => (
                  <p className="uk-text-right">
                    {Number(info.getValue()).toLocaleString()}
                  </p>
                ),
              }
            ),
          ],
        }),
        {
          header: "PET",
          style: "color-gray",
          columns: [
            columnHelper.accessor(
              (row) => scanRowAccessor(row, "pet_participants"),
              {
                id: "7",
                header: () => (
                  <HeaderCell
                    title={apiColumns[7].title}
                    info={apiColumns[7].description}
                  />
                ),
                style: "color-blue",
                sortDescFirst: true,
                cell: (info) => (
                  <p className="uk-text-right">
                    {Number(info.getValue()).toLocaleString()}
                  </p>
                ),
                footer: () => grandTotals[7].toLocaleString(),
              }
            ),
            columnHelper.accessor(
              (row) => scanRowAccessor(row, "pet_scans_submitted"),
              {
                id: "8",
                header: () => (
                  <HeaderCell
                    title={apiColumns[8].title}
                    info={apiColumns[8].description}
                  />
                ),
                style: "color-blue",
                sortDescFirst: true,
                footer: () => grandTotals[8].toLocaleString(),
                cell: (info) => (
                  <p className="uk-text-right">
                    {Number(info.getValue()).toLocaleString()}
                  </p>
                ),
              }
            ),
          ],
        },
      ],
    }),
    columnHelper.accessor((row) => scanRowAccessor(row, "url"), {
      enableSorting: false,
      id: "9",
      header: () => <HeaderCell title={"ADRC-specific SCAN QC Dashboards"} />,
      style: "color-gray",
      cell: (info) => (
        <a
          href={info.getValue()}
          className="uk-button uk-button-primary uk-button-small uk-text-nowrap "
          target="blank"
          rel="noopener"
        >
          Private Dash
        </a>
      ),
    }),
  ]

  const { data, lastModified, loading, error } = useScanData()
  const [sorting, setSorting] = useState([{ id: "3", desc: true }])
  const [globalFilter, setGlobalFilter] = useState("")

  // Build a CSV from the filtered rows and download it
  function exportCsv(rows) {
    const csvContent = csvFormat(rows, [
      "center_name",
      "mri_participants",
      "mri_exams_submitted",
      "mri_series_submitted",
      "pet_participants",
      "pet_scans_submitted",
      "total_participants",
      "adc_id",
    ])
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
    saveAs(
      blob,
      `SCAN_public_dashboard_${new Date(lastModified).toISOString()}.csv`
    )
  }

  const table = useReactTable({
    data,
    columns,
    state: { sorting, globalFilter },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableGlobalFilter: true,
    globalFilterFn: "includesString",
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
  })

  const grandTotals = useMemo(() => {
    if (!table) {
      return null // Ensure the table is defined
    }
    const rowIds = [3, 4, 5, 6, 7, 8]

    // Initialize the totals array with zeros for each rowId
    const totals = {}
    rowIds.forEach((rowId) => (totals[rowId] = 0))

    const filteredRows = table.getFilteredRowModel().rows
    // calculate sum totals for each column
    filteredRows.forEach((row) => {
      rowIds.forEach((rowId) => {
        totals[rowId] += Number(row.getValue(rowId))
      })
    })

    return totals
  }, [data, globalFilter])

  // LOADING
  if (loading) {
    return (
      <ScanDashLayout>
        <div>Loading...</div>
      </ScanDashLayout>
    )
  }

  // ERROR
  if (error) {
    return (
      <ScanDashLayout>
        <p className="uk-label uk-label-warning uk-text-bold">Error: {error}</p>
      </ScanDashLayout>
    )
  }

  return (
    <ScanDashLayout>
      <div className="uk-flex uk-flex-bottom uk-margin-left uk-margin-right">
        <h3 className="uk-flex-1 uk-text-baseline">
          Alzheimer&apos;s Disease Research Centers
        </h3>
        <button
          onClick={() => exportCsv(data)}
          style={{ textAlign: "center" }}
          className="uk-button-primary uk-margin uk-margin-small-right uk-button uk-button-small "
        >
          Copy CSV
        </button>
        <div className="uk-margin">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            placeholder="Search"
            className="uk-input uk-form-width-medium uk-form-small"
          />
        </div>
      </div>
      <div className="uk-overflow-auto">
        <table
          id="scandash"
          className="uk-table uk-table-small uk-table-striped bg-color-light-blue-2 "
          style={{ border: "1px solid lightgray" }}
        >
          <thead className="">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="color-blue ">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`uk-text-small ${header.column.columnDef.style} }`}
                    style={
                      header.isPlaceholder
                        ? { borderRight: "1px solid lightgray" }
                        : {
                            borderRight: "1px solid lightgray",
                            borderBottom: "1px solid lightgray",
                          }
                    }
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "uk-flex uk-flex-center uk-flex-bottom uk-items-center"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                          style: header.column.getCanSort()
                            ? { cursor: "pointer" }
                            : null,
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanSort() && (
                          <div className="flex flex-col uk-text-large uk-flex uk-flex-column uk-margin-small-left">
                            <span
                              className={
                                header.column.getIsSorted() === "asc"
                                  ? "uk-text-primary"
                                  : "uk-text-muted"
                              }
                              onClick={(event) => {
                                event.stopPropagation()
                                header.column.getIsSorted() === "asc"
                                  ? header.column.clearSorting()
                                  : header.column.toggleSorting(false)
                              }}
                            >
                              ˄
                            </span>
                            <span
                              className={
                                header.column.getIsSorted() === "desc"
                                  ? "uk-text-primary"
                                  : "uk-text-muted"
                              }
                              onClick={(event) => {
                                event.stopPropagation()
                                header.column.getIsSorted() === "desc"
                                  ? header.column.clearSorting()
                                  : header.column.toggleSorting(true)
                              }}
                            >
                              ˅
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
            <tr className="">
              {table.getLeafHeaders().map(
                (header) =>
                  // This is almost certainly a wrong way to do this:
                  header.depth === 3 && (
                    <td
                      className="uk-text-right uk-text-small uk-text-bold "
                      key={header.id}
                    >
                      {flexRender(header.column.columnDef.footer)}
                    </td>
                  )
              )}
            </tr>
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map(
                  (cell) =>
                    cell.getValue() && (
                      <td key={cell.id} className="uk-text-small">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="uk-flex uk-flex-between">
          <div className="uk-text-meta uk-padding-small">
            Data last updated: {new Date(lastModified).toLocaleString()}
          </div>
          <div className="uk-padding-small uk-text-right uk-text-meta">
            If you have an issue or questions about the SCAN dashboard, please
            reach out to{" "}
            <a className="uk-button-text" href="mailto: naccmail@uw.edu">
              naccmail@uw.edu
            </a>
            .
          </div>
        </div>
      </div>
    </ScanDashLayout>
  )
}
